<template>
  <div>
    <!-- header -->
    <div :style="`height:40vh; background:url(https://i.insider.com/5aba7287433c3720008b47d4?width=1100&format=jpeg&auto=webp); background-size:cover; margin-top:-100px`">
    </div>

    <!-- content -->      
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="title">
          MI COMPRA
        </div>
        <hr class="title-line">
      </div>      
    </div>
    <!-- <button @click="$bvModal.show('modal-detailOutOfStock')">test </button> -->
    <div v-if="this.allProducts.length==0" class="container text-center">
      <h4 class="mb-5">No tienes productos en tu carrito!</h4>
    </div>

    <div v-if="this.allProducts.length>0" class="container">
      <div class="row mb-5">
        <div class="col-md-6 col-lg-7">
          <div class="row shadow mb-2" v-for="(product, i) in allProducts" :key="i">
            <div class="col-lg-11 col-md-11 mt-3">
                <product-detail-vue :productDetail="product" :checkout="true"/>
            </div>
            <div class="col-lg-1 col-md-1 mt-3">
                <button @click="del(product)" class="center-vertical" style="background:#fd306e;color:white; border-radius:5px; border: solid 1px #fff;" type="button" >
                  <b-icon-trash-fill style="font-size:1.75rem;"> </b-icon-trash-fill>
                </button>
            </div>                
          </div>
        </div>
        <div class="col-md-4 offset-md-1 shadow">
             <h5 class="mb-4">Detalle</h5>
             <!-- <hr /> -->
              <div class="row ml-3 mb-2">
                <div class="flex justify-between">
                  <span class="text--disabled mb-1 text-lg mr-2">Sub total:</span>
                  <span class="text--disabled mb-1 text-lg ">Q {{total.toFixed(2)}} </span><br/>
                  <span v-if="shipping == 1" class="text--disabled mb-1 text-lg mr-2">Envío:</span>
                  <span v-if="shipping == 1" class="text--disabled mb-1 text-lg">Q {{paymentAgainstDelivery.toFixed(2)}} </span><br/>
                  <span v-if="paymentMethodId==4" class="text--disabled mb-1 text-lg mr-2">Recargo TC:</span>
                  <span v-if="paymentMethodId==4" class="text--disabled mb-1 text-lg">Q {{tcComission.toFixed(2)}} </span> <br/>
                  <span v-if="paymentMethodId==1 || (this.paymentMethodId == 3 && this.shipping==2)" class="text--disabled mb-1 text-lg mr-2">Descuento en compra:</span>
                  <span v-if="paymentMethodId==1 || (this.paymentMethodId == 3 && this.shipping==2)" class="text--disabled mb-1 text-lg">Q -{{discount.toFixed(2)}} </span><br/>
                </div>
              </div>
              <hr>
              <div class="row ml-3">
                <div class="flex justify-between">
                  <strong><span class="text--disabled mb-1 text-lg mr-2">Total:</span></strong>
                  <strong><span class="text--disabled mb-1 text-lg">Q {{finalTotal.toFixed(2)}} </span></strong>
                </div>
              </div>
              <button @click.prevent="postOrder" style="width:100%" class="btn bg-dark text-light mt-3 " :disabled="isLoading">
                Confirmar Pedido <div v-if="isLoading" class="spinner-border ml-2" style="width:20px; height:20px"></div>
              </button>
        </div>
      </div>
      <div class="row shadow mb-5">
        <div class="col-md-6 mb-3">

          <h4 class="mb-4 mt-2 ml-2">Selecciona Método de pago:</h4>
          <b-form-radio-group id="radios1"
            radio
            stacked
            @change="verifyPrice"
            v-model="paymentMethodId"
            :options="options"
            name="radioOptions">
          </b-form-radio-group>          
        </div>

        <div class="col-md-6 mb-3">
          <h4 class="mb-4 mt-2 ml-2">Envío:</h4>
          <b-form-radio-group id="radiosShipping"
            radio
            stacked
            :value="shipping"
            v-model="shipping"
            :disabled="shipping==1 && paymentMethodId==3"
            @change="verifyPrice"
            :options="optionsShipping"
            name="radioOptionsShipping">
          </b-form-radio-group>    
          <div class="row mt-3" v-if="shipping==1">
            <div class="col-md-12">
              <label for="shippingAddress">Dirección de envío</label><br>
              <input style="width:100%" clas="form-control" type="text" v-model="shippingAddress" />
            </div>
            <div class="col-md-12 mt-2">
              <label for="shippingAddress">Municipio</label><br>
              <input style="width:100%" clas="form-control" type="text" v-model="shippingCity" />
            </div>
            <div class="col-md-12 mt-2">
              <label for="shippingAddress">Departamento</label><br>
              <input style="width:100%" clas="form-control" type="text" v-model="shippingState" />
            </div>
          </div>      
        </div>
      </div>
      <div class="shadow mb-5">
        <h4 class="">Datos de facturación:</h4>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="mt-3" for="shippingAddress">N I T</label><br>
            <input style="width:100%" clas="form-control" type="text" v-model="nit" />
            <label class="mt-3" for="shippingAddress">Nombre</label><br>
            <input style="width:100%" clas="form-control" type="text" v-model="customerName" />
          </div>
          <div class="col-md-6 mb-3">
            <label class="mt-3" for="shippingAddress">Dirección</label><br>
            <input style="width:100%" clas="form-control" type="text" v-model="address" />
            <label class="mt-3" for="shippingAddress">Teléfono</label><br>
            <input style="width:100%" clas="form-control" type="text" v-model="phone" />
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-thanks" hide-footer hide-header >
       <hr>
       <h1>GRACIAS</h1>
       <h1>POR SU COMPRA!</h1>
    <button @click="closeModal()" style="display:block; margin: auto" class="btn btn-dark">Cerrar</button>
    </b-modal>

    <b-modal id="modal-detailOutOfStock" hide-footer title="Hubo un problema al procesar tu pedido">
       <!-- <hr> -->
      <h6>No tenemos stock disponible para los siguientes productos:</h6>
      <div class="container">
        <div class="row text-center mt-4">
          <table class="table table-hover">
            <thead style="background-color:#111 !important; color:white">
              <tr>
                <th width="80%" class="text-center">Producto</th>
                <th width="20%" class="text-center">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item of dataResult" :key="item.productId">
                <td>
                  {{item.nameProduct}} / <b>{{item.nameVariation}}</b>
                </td>
                <td>
                  {{item.quantity}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>      

      </div>
      <button @click="$bvModal.hide('modal-detailOutOfStock')" style="display:block; margin: auto" class="btn bg-primary text-white">Cerrar</button>
    </b-modal>

  </div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import ProductDetailVue from "../components/ProductDetail.vue";

export default {
  components: { ProductVue, ProductDetailVue },
  data: () => ({
    categories: [],
    url: null,
    allProducts: [],
    filteredProducts: [],
    discount: 0,
    deliveryCharges: 0,
    total: 0,
    finalTotal: 0,
    today: null,
    paymentMethodId: null,
    storeId: null,
    options: [
      {
        text: "Pago completo del pedido - (depósito o transferencia)",
        value: "1",
      },
      // { text: 'Pago parcial del pedido - (depósito o transferencia)', value: '2' },
      { text: "Pago contra Entrega", value: "3" },
      { text: "Pago con Tarjeta de Crédito", value: "4" },
    ],
    optionsShipping: [
      { text: "Recoger en tienda", value: "2" },
      { text: "Envío a mi dirección", value: "1" },
    ],
    tcComission: 0, //6%
    previousDeposit: 0, //5% descuento
    paymentAgainstDelivery: 35, //
    storePickup: 0, // 5% descuento,
    shipping: 1,
    shippingAddress: "",
    shippingCity: "",
    shippingState: "",

    nit: "",
    address: "",
    customerName: "",
    phone: "",
    stockTest: [
      { productId: 1338, quantity: 2, price: 8.2, variationId: 3080 },
      { productId: 1337, quantity: 3, price: 6.2, variationId: 3074 },
    ],
    dataResult: [],
    isLoading: false,
  }),
  async mounted() {
    this.storeId = JSON.parse(localStorage.getItem("storeId")) || 1;
    this.$store.watch(
      (state) => state.refreshCartInCheckOutKey,
      (value) => {
        this.calculatePice();
      }
    );

    this.today = await this.$store.dispatch("parseDate", new Date());
    try {
      if (localStorage.getItem("productDetail-" + this.storeId))
        this.allProducts = JSON.parse(
          localStorage.getItem("productDetail-" + this.storeId)
        );

      this.allProducts = this.mergeProducts();
      await this.setDiscounts();

      //this.calculatePice();
      this.verifyPrice();
      //datos de facturación
      const user = JSON.parse(localStorage.getItem("user")) || false;
      if (user) {
        this.address = user.address;
        this.nit = user.nit;
        this.customerName = user.name;
        this.phone = user.phone;
      }
    } catch (error) {
      console.log("error");
    }
  },
  methods: {
    async verifyPrice() {
      await this.calculatePice();
      if (this.paymentMethodId == 3) {
        this.tcComission = 0;
        this.discount = 0;
        this.shipping = 1;
        this.finalTotal = this.total + this.paymentAgainstDelivery;
      }
      if (this.paymentMethodId == 4) {
        this.discount = 0;
        this.tcComission = this.finalTotal * 0.06;
        this.finalTotal = this.finalTotal + this.tcComission; //+ this.paymentAgainstDelivery
      }
      if (this.paymentMethodId == 1 || this.deliveryMethodId == 2) {
        //|| this.paymentMethodId==3
        this.tcComission = 0;
        const discount = this.total * 0.05;
        this.discount = discount;
        this.finalTotal = this.finalTotal - this.discount;
      }
      //si eenvio a mi direccion agregar 35
      //creo que está de mas 24-03-2021
      if (this.shipping == 1 && this.paymentMethodId != 3) {
        this.finalTotal = this.finalTotal + this.paymentAgainstDelivery;
      }
      //dependiendo de la forma de pago asi se agrega %
    },
    async postOrder() {
      if (this.paymentMethodId === null) {
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Falta método de pago!",
        });
        return;
      }
      if (this.storeId === null) {
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Falta Tienda!",
        });
        return;
      }
      //verificar total compra>0
      if (
        this.finalTotal === null ||
        this.finalTotal == 0 ||
        this.allProducts.length == 0
      ) {
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "No hay productos en carrito!",
        });
        return;
      }
      if (
        this.shipping == 1 &&
        (this.shippingAddress == "" ||
          this.shippingCity == "" ||
          this.shippingState == "")
      ) {
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Falta dirección de envío / Municipio / Departamento!",
        });
        return;
      }
      // nit: this.nit,
      // address: this.address,
      // customerName: this.customerName,
      // phone: this.phone
      if (
        this.nit === "" ||
        this.address === "" ||
        this.customerName === "" ||
        this.phone === ""
      ) {
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Revisar datos de facturación!",
        });
        return;
      }

      this.isLoading = true;

      const user = JSON.parse(localStorage.getItem("user"));
      let customerId;
      if (user) {
        customerId = user.id;
      } else {
        customerId = 0;
      }

      try {
        let order = {
          date: this.today,
          total: this.total, //this.finalTotal,
          deliveryMethodId: this.shipping,
          paymentMethodId: this.paymentMethodId,
          customerId: customerId,
          employeeId: 0,
          storeId: this.storeId,
          shippingAddress: this.shippingAddress,
          city: this.shippingCity,
          state: this.shippingState,
          discount: this.discount,
          tcCost: this.tcComission,
          deliveryCost: this.shipping == 1 ? 35 : 0,
          nit: this.nit,
          address: this.address,
          customerName: this.customerName,
          phone: this.phone,
        };
        let details = [...this.allProducts];
        details = details.map((item) => {
          const product = {
            productId: item.id,
            quantity: item.quantity,
            price: item.price,
            variationId: item.variation.variationId,
            nameProduct: item.name,
            nameVariation: item.variation.name,
          };
          return product;
        });

        const data = {
          table: "order",
          data: {
            order: order,
            details: details,
          },
        };
        this.dataResult = await this.$store.dispatch("saveNew", data);
        // console.log(this.dataResult)
        console.log("resultado data", this.dataResult);
        if (this.dataResult.message) {
          localStorage.removeItem("productDetail-" + this.storeId);
          this.$store.commit("refreshCart");
          this.$swal({
            icon: "success",
            title: "Muy bien!",
            text: `Pedido #${this.dataResult.insertId} agregado correctamente!.`,
          });
          this.$router.push("/");
        } else {
          //this.dataResult = JSON.parse(result)
          if (this.dataResult.length > 0) {
            localStorage.removeItem("productDetail-" + this.storeId);
            this.$store.commit("refreshCart");
            this.$bvModal.show("modal-detailOutOfStock");
            this.allProducts = [];
            //this.$router.push("/");
          } else {
            this.$swal({
              icon: "error",
              title: "Oopss!",
              text: "Error, intentalo nuevamente!." + error,
            });
          }
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async calculatePice() {
      for await (let item of this.allProducts) {
        item.total = item.quantity * item.price;
      }
      let total = this.allProducts.reduce((a, b) => {
        return a + Number(b.price * b.quantity);
      }, 0);

      this.finalTotal = total;
      this.total = total; //this.finalTotal;
    },
    del(item) {
      let pos = this.allProducts.indexOf(item);
      this.allProducts.splice(pos, 1);
      localStorage.setItem(
        "productDetail-" + this.storeId,
        JSON.stringify(this.allProducts)
      );
      this.calculatePice();
      this.$store.commit("refreshCart");
    },
    async getCategories() {
      try {
        const url = this.url + "categories";
        const req = await fetch(url);
        if (req.ok) {
          this.categories = await req.json();
        } else {
          throw await req.text();
        }
      } catch (error) {
        throw error;
      }
    },

    async getPricesList(id, quantity) {
      try {
        const query = await this.$store.dispatch(
          "getAll",
          "productPrice/" + id + "/" + quantity
        );
        return query.price;
      } catch (error) {}
    },

    mergeProducts() {
      let newList = [];
      this.allProducts.forEach((product) => {
        let newProduct = newList.find(
          (p) => p.id === product.id && p.variation.id === product.variation.id
        );
        if (!newProduct) {
          newList.push(product);
        } else {
          newProduct.quantity = newProduct.quantity + product.quantity;
        }
      });
      return newList;
    },

    async setDiscounts() {
      try {
        let uniques = [...new Set(this.allProducts.map((data) => data.id))];
        let sumary = uniques.map((item) => {
          const sum = this.allProducts
            .filter((pro) => pro.id === item)
            .map((p) => p.quantity)
            .reduce((a, c) => {
              return a + c;
            });
          let result = { id: item, quantity: sum };
          return result;
        });

        for (let i = 0; i < sumary.length; i++) {
          const item = sumary[i];
          item.price = await this.getPricesList(item.id, item.quantity);
        }

        this.allProducts.map((product) => {
          let searchedProduct = sumary.find((p) => p.id == product.id);
          product.price = searchedProduct.price;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.center-vertical {
  position: absolute;
  top: 40%;
  left: 10%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .center-vertical {
    position: absolute;
    top: 40%;
    left: 10%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    text-align: center;
  }
}
.shadow {
  /* border: 1px solid; */
  padding: 10px;
  box-shadow: 5px 10px 8px #888888;
}
.bold {
  font-weight: bold;
}
</style>